import React from 'react';
import { Helmet } from 'react-helmet';

export interface LayoutProps {
    title: string;
    children: React.ReactNode;
}

const Layout = (props: LayoutProps) => {
    const { title, children } = props;

    return (
        <>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            {children}
        </>
    );
};

export default Layout;
